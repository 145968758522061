import { logger } from "../logger";
import { getBoolean, getNumber, getValue, getVolatileStorage, IStorage, Keys, removeValue, setBoolean, setNumber, setValue } from "./common";

const isLocalStorageAvailable = (): boolean => {
    try {
        const storage = window["localStorage"];
        const x = "__storage_test__";
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch (e) {
        return false;
    }
}

const storage: IStorage = isLocalStorageAvailable() ? localStorage : getVolatileStorage("local");

export function clearStorage(): void {
    storage.clear();
}

const getDate = (key: Keys): Date | null => {
    const tsn = getNumber(storage, key);
    if (tsn != null) {
        const d = new Date(tsn);
        if (!isNaN(d.getTime())) {
            // The Date is valid
            return d
        }
    }

    return null;
}

const setDate = (key: Keys, date: Date) => setNumber(storage, key, date.getTime());

export const getLastModemSwapDisplay = (): Date | null => getDate(Keys.LastModemSwapDisplay);
export const setLastModemSwapDisplay = (date: Date) => setDate(Keys.LastModemSwapDisplay, date);

export const getLastModemResetDate = (): Date | null => getDate(Keys.LastModemResetDate);
export const setLastModemResetDate = (date: Date) => setDate(Keys.LastModemResetDate, date);

export const getModemResetCount = (): number | null => getNumber(storage, Keys.ModemResetCount);
export const setModemResetCount = (value: number) => setNumber(storage, Keys.ModemResetCount, value);

export const getHistory = (): string | null => getValue(storage, Keys.History);
export const setHistory = (data: string) => {
    setValue(storage, Keys.History, data);
}
export const removeHistory = () => removeValue(storage, Keys.History);

export const getHistoryLegacy = (): string | null => getValue(storage, Keys.HistoryLegacy);
export const setHistoryLegacy = (data: string) => setValue(storage, Keys.HistoryLegacy, data);

type DidMigrateData = null | "true"; // Describes the data under LocalStorageKey.DidMigrate key
export const getHistoryDidMigrate = (): boolean => {
    const retVal = getValue(storage, Keys.HistoryDidMigrate) as DidMigrateData;
    return retVal === "true";
}
export const setHistoryDidMigrate = (): void => {
    const data: DidMigrateData = "true";
    setValue(storage, Keys.HistoryDidMigrate, data);
}

export const getPrivacyPolicyAcceptedDate = (): Date | null => getDate(Keys.PrivacyPolicyAcceptedDate);
export const setPrivacyPolicyAcceptedDate = (value: Date) => setDate(Keys.PrivacyPolicyAcceptedDate, value);

export const getOfferedSurvey = (): boolean | null => 
    getBoolean(storage, Keys.OfferedSurvery);
export const setOfferedSurvery = (value: boolean) =>
    setBoolean(storage, Keys.OfferedSurvery, value);


export interface ConsentProps {
    analytics: boolean;
    bugReports: boolean;
    timestamp: Date;
}

export const getConsentProps = (): ConsentProps | null => {
    const dateTimeReviver = (key: string, value: any) => {
        if (typeof value === "string") {
            return new Date(value);
        } else {
            return value;
        }        
    }

    const value = getValue(storage, Keys.Consent);
    if (value === null) {
        return null;
    } else {
        try {
            return JSON.parse(value, dateTimeReviver) as ConsentProps;
        } catch (err) {
            logger.error("getConsentProps", err);
            return null;
        }
    }
}

export const setConsentProps = (consentProps: ConsentProps) => setValue(storage, Keys.Consent, JSON.stringify(consentProps));