import {
    BenchmarkDE,
    DownloadDE,
    ModemDE,
    PingDE,
    PrepareResultDE,
    UploadDE,
    ModemFinalKind,
    CompleteDE,
    NQValueKind,
    HintsDE
} from "@visonum/network-quality-sdk";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from "../../store";
import { Subscription } from "rxjs";
import { reportNQError, reportWarning } from "../errors/errorSlice";
import { AppAssertionError } from "../errors/AppAssertionError";
import { logger } from "../../helper/logger";
import { currentSubscription, getSdk } from "../../networkQuality";
import { appendStoreNQValues } from "../history/historySlice";
import { updateAfterMeasurement } from "../partialService/updateAfterMeasurement";
import { VARIANT } from "../../config";
import { setScreen } from "../focusManager/focusManagerSlice";
import ViewScreenKey from "../focusManager/ViewScreenKey";
import { getNqError } from "../errors/errorUtils";
import { updateNqRecordHints } from "../hints/hintsSlice";
import { MeasurementState, Phase } from "./types";
import { showPrivacyPolicyDialog } from "../dialogManager/dialogManagerSlice";

const initialState = {
    kind: Phase.NotStarted
} as MeasurementState;

/**
 * Shows Privacy Policy Dialog if Privacy Policy is not accepted.
 * Starts measurement if Privacy Policy is accepted.
 * @returns true if measurement is started otherwise false
 */
export const checkPrivacyAndStartMeasurement = (): AppThunk<boolean> => (dispatch, getState) => {
    dispatch(reset());

    const privacyPolicyState = getState().privacyPolicy;
    if (!privacyPolicyState.privacyPolicyAccepted) {
        dispatch(showPrivacyPolicyDialog());
        return false;
    } else {
        dispatch(startRunning());
        currentSubscription.subscription = getSdk().speedtest().subscribe({
            next: (value) => {
                if (value.kind === NQValueKind.PrepareResult) {
                    dispatch(updatePrepareResult(value));
                } else if (value.kind === NQValueKind.BenchmarkIntermediate || value.kind === NQValueKind.BenchmarkFinalAvailable || value.kind === NQValueKind.BenchmarkFinalDidNotRun) {
                    dispatch(updateBenchmark(value))
                } else if (value.kind === NQValueKind.DownloadIntermediate || value.kind === NQValueKind.DownloadFinal) {
                    dispatch(updateDownload(value))
                } else if (value.kind === NQValueKind.UploadIntermediate || value.kind === NQValueKind.UploadFinal) {
                    dispatch(updateUpload(value))
                } else if (value.kind === NQValueKind.PingIntermediate || value.kind === NQValueKind.PingFinal) {
                    dispatch(updatePing(value))
                } else if (value.kind === NQValueKind.Complete) {
                    dispatch(updateComplete(value))
                } else if (value.kind === NQValueKind.ModemFinal || value.kind === NQValueKind.ModemIntermediateExecuting || value.kind === NQValueKind.ModemIntermediateResolving) {
                    dispatch(updateModem(value));
                } else if (value.kind === NQValueKind.Hints) {
                    dispatch(updateHints(value));
                } else if (value.kind === NQValueKind.Warning) {
                    dispatch(reportWarning(value));
                }
            },
            complete: async () => {
                logger.info("ms start complete");
                const nqRecord = await dispatch(appendStoreNQValues());
                dispatch(complete());
                if (nqRecord !== null) {
                    dispatch(updateAfterMeasurement(nqRecord));
                }
                if (VARIANT === "TV") {
                    dispatch(setScreen(ViewScreenKey.Result));
                }
                //Call updateNqRecordHints() after appendStoreNQValues() and updateAfterMeasurement() have called
                await dispatch(updateNqRecordHints());
            },
            error: (err) => {
                logger.error("ms start error", err);
                dispatch(reportNQError(getNqError(err)));
                dispatch(cancel());
            }
        });
        return true;
    }
}

export const cancel = (subscription?: Subscription | null): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const kind = getState().measurement.kind;
    logger.info(`cancel() current stage is ${kind}`);
    subscription?.unsubscribe();
    if (kind != Phase.NotStarted && kind != Phase.Aborting && kind != Phase.FinishedNoModem && kind != Phase.FinishedWithModem) {
        await dispatch(abort());
    }
}

export const abort = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const state = getState().measurement;
    switch (state.kind) {
        case Phase.NotStarted:
        case Phase.Aborting:
        case Phase.FinishedNoModem:
        case Phase.FinishedWithModem:
            throw new AppAssertionError({ actual: state.kind, message: `Abort: illegal state ${state.kind}` });
        case Phase.Preparing:
        case Phase.ModemResolving:
        case Phase.ModemExecuting:
        case Phase.CompletingNoModem:
        case Phase.CompletingWithModem:
            dispatch(reset());
            break;
        case Phase.Benchmarking:
        case Phase.Downloading:
        case Phase.Uploading:
        case Phase.Pinging:
        case Phase.Completing:
            dispatch(startAbort());
            await new Promise<void>((resolve, reject) => {
                getSdk().abort(state.prepareResult).subscribe({
                    complete: () => resolve(),
                    error: (err) => reject(err),
                });
            });
            dispatch(reset());
            break;
    }
}

export const measurementSlice = createSlice({
    name: 'measurement',
    initialState,
    reducers: {
        startRunning: (_) => {
            return {
                kind: Phase.Preparing,
            }
        },
        updatePrepareResult: (_, action: PayloadAction<PrepareResultDE>) => {
            return {
                kind: Phase.Benchmarking,
                prepareResult: action.payload,
                benchmark: undefined,
            }
        },
        updateBenchmark: (state, action: PayloadAction<BenchmarkDE>) => {
            if (state.kind != Phase.Benchmarking) {
                throw new AppAssertionError({ actual: state.kind, expected: Phase.Benchmarking })
            }

            if (action.payload.kind === NQValueKind.BenchmarkIntermediate) {
                return {
                    kind: Phase.Benchmarking,
                    prepareResult: state.prepareResult,
                    benchmark: action.payload,
                }
            } else if (action.payload.kind === NQValueKind.BenchmarkFinalAvailable || action.payload.kind === NQValueKind.BenchmarkFinalDidNotRun) {
                return {
                    kind: Phase.Downloading,
                    prepareResult: state.prepareResult,
                    benchmark: action.payload,
                    downloadIntermediates: []
                }
            } else {
                throw new AppAssertionError({ actual: action.payload, expected: "BenchmarkIntermediate or BenchmarkFinalAvailable or BenchmarkFinalDidNotRun" })
            }
        },
        updateDownload: (state, action: PayloadAction<DownloadDE>) => {
            if (state.kind != Phase.Downloading) {
                throw new AppAssertionError({ actual: state.kind, expected: Phase.Downloading })
            }
            if (action.payload.kind === NQValueKind.DownloadIntermediate) {
                return {
                    kind: Phase.Downloading,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: [...state.downloadIntermediates, action.payload.speed],
                }
            } else if (action.payload.kind === NQValueKind.DownloadFinal) {
                return {
                    kind: Phase.Uploading,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: state.downloadIntermediates,
                    download: action.payload,
                    uploadIntermediates: []
                }
            } else {
                throw new AppAssertionError({ actual: action.payload, expected: "DownloadIntermediate or DownloadFinal" })
            }

        },
        updateUpload: (state, action: PayloadAction<UploadDE>) => {
            if (state.kind != Phase.Uploading) {
                throw new AppAssertionError({ actual: state.kind, expected: Phase.Uploading })
            }
            if (action.payload.kind === NQValueKind.UploadIntermediate) {
                return {
                    kind: Phase.Uploading,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: state.downloadIntermediates,
                    download: state.download,
                    uploadIntermediates: [...state.uploadIntermediates, action.payload.speed],
                }
            } else if (action.payload.kind === NQValueKind.UploadFinal) {
                return {
                    kind: Phase.Pinging,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: state.downloadIntermediates,
                    download: state.download,
                    uploadIntermediates: state.uploadIntermediates,
                    upload: action.payload,
                    pingIntermediates: []
                }
            } else {
                throw new AppAssertionError({ actual: action.payload, expected: "UploadIntermediate or UploadFinal" })
            }
        },
        updatePing: (state, action: PayloadAction<PingDE>) => {
            if (state.kind != Phase.Pinging) {
                throw new AppAssertionError({ actual: state.kind, expected: Phase.Pinging })
            }
            if (action.payload.kind === NQValueKind.PingIntermediate) {
                return {
                    kind: Phase.Pinging,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: state.downloadIntermediates,
                    download: state.download,
                    uploadIntermediates: state.uploadIntermediates,
                    upload: state.upload,
                    pingIntermediates: [...state.pingIntermediates, action.payload.pingTime],
                }
            } else if (action.payload.kind === NQValueKind.PingFinal) {
                return {
                    kind: Phase.Completing,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: state.downloadIntermediates,
                    download: state.download,
                    uploadIntermediates: state.uploadIntermediates,
                    upload: state.upload,
                    pingIntermediates: state.pingIntermediates,
                    ping: action.payload,
                }
            } else {
                throw new AppAssertionError({ actual: action.payload, expected: "PingIntermediate or PingFinal" })
            }
        },
        updateComplete: (state, action: PayloadAction<CompleteDE>) => {
            if (state.kind != Phase.Completing) {
                throw new AppAssertionError({ actual: state.kind, expected: Phase.Completing });
            }

            if (action.payload.kind === NQValueKind.Complete) {
                return {
                    kind: Phase.ModemResolving,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: state.downloadIntermediates,
                    download: state.download,
                    uploadIntermediates: state.uploadIntermediates,
                    upload: state.upload,
                    pingIntermediates: state.pingIntermediates,
                    ping: state.ping,
                    modem: undefined
                }
            } else {
                throw new AppAssertionError({ actual: action.payload, expected: "Complete" })
            }
        },
        updateModem: (state, action: PayloadAction<ModemDE>) => {
            if (state.kind != Phase.ModemResolving && state.kind != Phase.ModemExecuting) {
                throw new AppAssertionError({ actual: state.kind, expected: "Phase.ModemResolving or Phase.ModemExecuting" })
            }
            if (action.payload.kind === NQValueKind.ModemIntermediateResolving) {
                return {
                    kind: Phase.ModemResolving,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: state.downloadIntermediates,
                    download: state.download,
                    uploadIntermediates: state.uploadIntermediates,
                    upload: state.upload,
                    pingIntermediates: state.pingIntermediates,
                    ping: state.ping,
                    modem: action.payload,
                }
            } else if (action.payload.kind === NQValueKind.ModemIntermediateExecuting) {
                return {
                    kind: Phase.ModemExecuting,
                    prepareResult: state.prepareResult,
                    benchmark: state.benchmark,
                    downloadIntermediates: state.downloadIntermediates,
                    download: state.download,
                    uploadIntermediates: state.uploadIntermediates,
                    upload: state.upload,
                    pingIntermediates: state.pingIntermediates,
                    ping: state.ping,
                    modem: action.payload,
                }
            } else if (action.payload.kind === NQValueKind.ModemFinal) {
                const modemFinal = action.payload;
                const kind = modemFinal.modemFinal.kind;
                if (kind === ModemFinalKind.ModemFinalAvailable || kind === ModemFinalKind.ModemFinalFailed) {
                    return {
                        kind: Phase.CompletingWithModem,
                        prepareResult: state.prepareResult,
                        benchmark: state.benchmark,
                        downloadIntermediates: state.downloadIntermediates,
                        download: state.download,
                        uploadIntermediates: state.uploadIntermediates,
                        upload: state.upload,
                        pingIntermediates: state.pingIntermediates,
                        ping: state.ping,
                        modem: modemFinal,
                        hints: null,
                    }
                } else if (kind === ModemFinalKind.ModemFinalDidNotRun) {
                    return {
                        kind: Phase.CompletingNoModem,
                        prepareResult: state.prepareResult,
                        benchmark: state.benchmark,
                        downloadIntermediates: state.downloadIntermediates,
                        download: state.download,
                        uploadIntermediates: state.uploadIntermediates,
                        upload: state.upload,
                        pingIntermediates: state.pingIntermediates,
                        ping: state.ping,
                        modem: modemFinal,
                        hints: null,
                    }
                } else {
                    throw new AppAssertionError({ actual: modemFinal, expected: "ModemFinalAvailable or ModemFinalFailed or ModemFinalDidNotRun" });
                }
            } else {
                throw new AppAssertionError({ actual: action.payload, expected: "ModemIntermediateResolving or ModemIntermediateExecuting or ModemFinal" })
            }
        },
        updateHints: (state, action: PayloadAction<HintsDE>) => {
            if (state.kind != Phase.CompletingWithModem && state.kind != Phase.CompletingNoModem) {
                throw new AppAssertionError({ actual: state.kind, expected: "Phase.CompletingWithModem or Phase.CompletingWithModem" });
            }

            state.hints = action.payload;
        },
        reset: (state) => {
            logger.debug("Reset called", state);
            return initialState;
        },
        complete: (state) => {
            if (state.kind === Phase.CompletingNoModem) {
                state.kind = Phase.FinishedNoModem;
            } else if (state.kind === Phase.CompletingWithModem) {
                state.kind = Phase.FinishedWithModem;
            } else {
                throw new AppAssertionError({ actual: state.kind, expected: "Phase.CompletingNoModem or Phase.CompletingWithModem" })
            }
        },
        startAbort: state => {
            switch (state.kind) {
                case Phase.Benchmarking:
                case Phase.Downloading:
                case Phase.Uploading:
                case Phase.Pinging:
                case Phase.Completing:
                    return {
                        kind: Phase.Aborting
                    }
                default:
                    throw new AppAssertionError({ actual: state.kind, message: `Abort: illegal state ${state.kind}` });
            }
        }
    }
});

export const {
    startRunning,
    updatePrepareResult,
    updateBenchmark,
    updateDownload,
    updateUpload,
    updatePing,
    updateComplete,
    updateModem,
    updateHints,
    reset,
    complete,
    startAbort } = measurementSlice.actions

export default measurementSlice.reducer;
